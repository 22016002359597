// @flow
import type { Styles } from './types';
import getPaddingFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          maxWidth: 'initial',
          flexBasis: 'unset',
          width: `calc(100% + ${theme.spacing(
            getPaddingFactor(breakpoint) * 2,
          )}px)`,
          marginRight: theme.spacing(-getPaddingFactor(breakpoint)),
          marginLeft: theme.spacing(-getPaddingFactor(breakpoint)),
        },
      }),
      {},
    ),
  },
  image: {
    maxHeight: 700,
  },
});

export default styles;
