// @flow
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import type { CSSStyleRuleBase } from '@material-ui/core/styles/withStyles';
import merge from 'lodash/merge';
import mapValues from 'lodash/mapValues';
import castArray from 'lodash/castArray';
import isNil from 'lodash/isNil';

import type { GradientDirection } from '~plugins/material-ui/modules/gradients/types';
import getGradientColorStops from '~plugins/material-ui/modules/gradients/helpers/getGradientColorStops';
import getGradientCSSGradient from '~plugins/material-ui/modules/gradients/helpers/getGradientCSSGradient';

import type {
  ThemeOverrides,
  ThemeProps,
  BackgroundColor,
  PaddingFactor,
  PaddingFactorArray,
  PaddingFactorBreakpoints,
} from '../types';

export const getThemeProps = (theme: Theme): ThemeProps =>
  merge(
    ({
      paddingFactor: [6, 0],
      paddingAlterFactor: {
        xs: 1,
      },
    }: ThemeProps),
    // $FlowFixMe --> Reason: mui theme props
    theme.props?.BodySection,
  );

export const getThemeOverrides = (theme: Theme): ThemeOverrides =>
  merge(
    ({
      backgroundColors: {
        primary: theme.palette.primary.light,
        secondary: theme.palette.secondary.light,
        grey: theme.palette.grey[300],
        white: theme.palette.common.white,
        black: theme.palette.common.black,
      },
    }: ThemeOverrides),
    // $FlowFixMe --> Reason: mui theme overrides
    theme.overrides?.BodySection,
  );

export const getPaddingFactor = (
  theme: Theme,
  paddingFactor?: ?PaddingFactor,
) =>
  isNil(paddingFactor) ? getThemeProps(theme).paddingFactor : paddingFactor;

export const getPaddingAlterFactor = (
  theme: Theme,
): PaddingFactorBreakpoints<PaddingFactorArray> => {
  const rawAlterFactor = getThemeProps(theme).paddingAlterFactor || {};
  return theme.breakpoints.keys.reduce(
    (acc, key, index, keys) => ({
      ...acc,
      [key]: castArray(
        Number.isFinite(rawAlterFactor[key])
          ? rawAlterFactor[key]
          : (index === 0 && 1) || acc[keys[index - 1]],
      ),
    }),
    {},
  );
};

export const getPaddingFactorBreakpoints = (
  theme: Theme,
  paddingFactor?: ?PaddingFactor,
): PaddingFactorBreakpoints<PaddingFactorArray> => {
  const factor: PaddingFactorArray = castArray(
    getPaddingFactor(theme, paddingFactor),
  );
  return mapValues(
    getPaddingAlterFactor(theme),
    (rawAlterFactor: PaddingFactorArray) => {
      const alterFactor = castArray(rawAlterFactor);
      return factor.map(
        (factorValue, index) =>
          factorValue * alterFactor[index % alterFactor.length],
      );
    },
  );
};

type ReducePaddingFactorStylesIteratee = (
  factor: PaddingFactorArray,
  key: Breakpoint,
  PaddingFactorBreakpoints<PaddingFactorArray>,
) => { [string]: CSSStyleRuleBase };

const reducePaddingFactorStylesDefaultIteratee: (
  theme: Theme,
) => ReducePaddingFactorStylesIteratee = theme => factor => ({
  padding: theme.spacing(...factor),
});

export const reducePaddingFactorStyles = (
  theme: Theme,
  paddingFactor?: ?PaddingFactor,
  iteratee?: ReducePaddingFactorStylesIteratee = reducePaddingFactorStylesDefaultIteratee(
    theme,
  ),
): { [string]: CSSStyleRuleBase } => {
  const factors = getPaddingFactorBreakpoints(theme, paddingFactor);
  // $FlowFixMe --> Reason: Object.entries is not typed
  return Object.entries(factors).reduce(
    (acc, [key, factor]: [Breakpoint, PaddingFactorArray]) => ({
      ...acc,
      [theme.breakpoints.up(key)]: iteratee(factor, key, factors),
    }),
    {},
  );
};

export const getBackgroundColor = (
  theme: Theme,
  backgroundColor?: ?BackgroundColor,
) => backgroundColor || getThemeProps(theme).backgroundColor;

export const getBackgroundGradientDirection = (
  theme: Theme,
  backgroundGradientDirection?: ?GradientDirection,
) =>
  backgroundGradientDirection ||
  getThemeProps(theme).backgroundGradientDirection;

export const getBackgroundColorCSSValue = (
  theme: Theme,
  backgroundColor: ?BackgroundColor,
) => {
  const themeOverrides = getThemeOverrides(theme);
  return (
    (backgroundColor &&
      themeOverrides.backgroundColors &&
      themeOverrides.backgroundColors[backgroundColor]) ||
    undefined
  );
};

export const getBackgroundGradientCSSValue = (
  theme: Theme,
  backgroundColor: ?BackgroundColor,
  direction?: ?GradientDirection,
) => {
  const themeOverrides = getThemeOverrides(theme);

  const colorStops = getGradientColorStops(
    {
      colorStops: {
        ...themeOverrides.backgroundColors,
        ...themeOverrides.backgroundGradientColorStops,
      },
      coefficient: themeOverrides.backgroundGradientDefaultColorCoefficient,
    },
    backgroundColor,
  );

  return getGradientCSSGradient({ colorStops, direction }) || undefined;
};
