// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import map from 'lodash/map';
import ContainerDimensions from 'react-container-dimensions';
import Fade from 'react-reveal/Fade';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import RichText from '~plugins/prismic/components/RichText';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import StoriesItem from './StoriesItem';
import type { Props } from './types';
import styles from './styles';
import videoFrame from '../../../images/videoFrame.png';

const Stories = React.lazy(() => import('./Stories'));

const StoriesSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';
  const theme = useTheme<Theme>();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const defaultStyles = {
    width: '100%',
    padding: '90px 14px 14px 14px',
    background: '#0E0E0E',
    color: 'white',
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const storiesComponents = data.items
    ? map(data.items, (item, index: number) => {
        return item
          ? {
              // eslint-disable-next-line react/display-name
              content: ({ action, isPaused }) => {
                const handleClick = e => {
                  e.preventDefault();
                  action(isPaused ? 'play' : 'pause');
                };
                return (
                  <StoriesItem
                    key={index}
                    title={item?.item_title?.text}
                    description={item?.item_description?.text}
                    backgroundImage={
                      isSmDown ? item?.item_small_image : item?.item_image
                    }
                    backgroundImageAlt={
                      isSmDown
                        ? item?.item_small_image?.alt || ''
                        : item?.item_image?.alt || ''
                    }
                    textVerticalPosition={item?.item_text_vertical_position}
                    textHorizontalPosition={item?.item_text_horizontal_position}
                    color={item?.item_text_color}
                    onClick={handleClick}
                    onClose={handleClose}
                    classes={React.useMemo(
                      () => ({
                        root: classes.story,
                        textWrapper: classes.storyTextWrapper,
                        title: classes.storyTitle,
                        description: classes.storyDescription,
                        backgroundImage: classes.storyImage,
                        action: classes.storyCloseIcon,
                      }),
                      [classes],
                    )}
                  />
                );
              },
            }
          : null;
      })
    : [
        {
          header: {
            heading: 'Heading test',
            subheading: 'Subheading test',
          },
          // eslint-disable-next-line react/display-name
          content: () => (
            <div style={defaultStyles}>
              <Typography variant="h5">This is the content</Typography>
            </div>
          ),
          url: videoFrame,
          duration: 5000,
        },
      ];

  const StoriesFallback = (
    <div className={classes.fallbackWrapper}>
      <CircularProgress color="primary" className={classes.fallback} />
    </div>
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Fade>
        <Container
          component={ResPadding}
          maxWidth="sm"
          className={classes.textWrapper}
        >
          <Typography
            variant="h4"
            variantMapping={{ h4: 'h1' }}
            className={classes.title}
          >
            {data.primary?.slice_title?.text}
          </Typography>
          <RichText
            {...data.primary?.slice_description}
            className={classes.description}
          />
          <Button
            variant="contained"
            color="primary"
            disableTouchRipple
            aria-label={data.primary?.slice_button_label?.text}
            onClick={handleClickOpen}
            className={classes.action}
          >
            {data.primary?.slice_button_label?.text}
          </Button>
        </Container>
      </Fade>
      <Dialog open={open} onClose={handleClose} fullScreen>
        <ContainerDimensions>
          {({ width, height }) => (
            <React.Suspense fallback={StoriesFallback}>
              <Stories
                stories={storiesComponents}
                defaultInterval={4500}
                onAllStoriesEnd={handleClose}
                className={classes.storiesContainer}
                width={width}
                height={height}
              />
            </React.Suspense>
          )}
        </ContainerDimensions>
      </Dialog>
    </Component>
  );
};

StoriesSlice.defaultProps = {
  className: undefined,
};

export const StyledStoriesSlice = withStyles<*, *, Props>(styles)(StoriesSlice);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledStoriesSlice>,
>()(StyledStoriesSlice);
