// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  wrapper: {
    position: 'relative',
    padding: theme.spacing(2),
    color: theme.palette.common.white,
  },
  backgroundVideo: {},
  siteIcon: {
    width: '100%',
    '& img': {
      objectFit: 'contain !important',
    },
  },
  description: {
    marginBottom: theme.spacing(4),
    textShadow: '1px 1px 6px #565656',
  },
  form: {},
  formField: {},
  formButton: {},
  formPrivacyPolicy: {},
});

export default styles;
