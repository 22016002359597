// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import NodeImage from '~plugins/prismic/components/NodeImage';
import type { PrismicImageType } from '~schema';

export type ClassKey =
  | 'root'
  | 'textWrapper'
  | 'limitTextWrapperWidth'
  | 'titleWrapper'
  | 'title'
  | 'descriptionWrapper'
  | 'description'
  | 'textOverlay'
  | 'backgroundImage'
  | 'action';

const defaultVerticalPosition = 'Top';
const defaultHorizontalPosition = 'Left';

const getTextVerticalPosition = (
  textVerticalPosition = defaultVerticalPosition,
) =>
  ({
    Top: {
      justifyContent: 'flex-start',
    },
    Middle: {
      justifyContent: 'center',
    },
    Bottom: { justifyContent: 'flex-end' },
  }[textVerticalPosition || 'Top']);

const getTextHorizontalPosition = (
  textHorizontalPosition = defaultHorizontalPosition,
) =>
  ({
    Left: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    Center: {
      alignItems: 'center',
      textAlign: 'center',
    },
    Right: { alignItems: 'flex-end', textAlign: 'right' },
  }[textHorizontalPosition || 'Left']);

const getStoriesTextColor = color =>
  color !== null ? { color: color } : { color: '#0E0E0E' };

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  title?: ?string,
  description?: ?string,
  backgroundImage?: ?PrismicImageType,
  backgroundImageAlt?: string,
  onClose?: () => void,
  textVerticalPosition?: ?string,
  textHorizontalPosition?: ?string,
  color?: ?string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    padding: `${theme.spacing(6)}px ${theme.spacing(2)}px ${theme.spacing(
      10,
    )}px ${theme.spacing(2)}px`,
    position: 'relative',
    width: '100%',
    '-webkit-user-select': 'none',
    '-webkit-touch-callout': 'none',
    color: theme.palette.background.default,
  },
  textWrapper: ({ textVerticalPosition, textHorizontalPosition, color }) => ({
    position: 'inherit',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    ...getStoriesTextColor(color),
    ...getTextVerticalPosition(textVerticalPosition),
    ...getTextHorizontalPosition(textHorizontalPosition),
  }),
  limitTextWrapperWidth: {
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '50vw',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '30vw',
    },
  },
  titleWrapper: {},
  title: {
    lineHeight: 1.5,
    overflowWrap: 'break-word',
    hyphens: 'auto',
    [theme.breakpoints.up('sm')]: {
      lineHeight: 1.2,
    },
    [theme.breakpoints.up('lg')]: {
      lineHeight: 1.1,
    },
  },
  descriptionWrapper: {
    '* + &': {
      marginTop: theme.spacing(3),
    },
  },
  description: {
    fontWeight: 500,
    lineHeight: 2.5,
    [theme.breakpoints.up('sm')]: {
      lineHeight: 2.2,
    },
    [theme.breakpoints.up('lg')]: {
      lineHeight: 2.1,
    },
  },
  textOverlay: {
    display: 'inline',
    padding: theme.spacing(1),
    background: 'rgba(255, 255, 255, 0.7)',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute !important',
    top: 0,
    left: 0,
  },
  action: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: '50%',
    transform: 'translate(-50%, 0px)',
    color: 'inherit',
    zIndex: 9999,
    minWidth: 82,
  },
});

const StoriesItem = ({
  title,
  description,
  backgroundImage,
  backgroundImageAlt,
  onClose,
  color,
  textVerticalPosition,
  textHorizontalPosition,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classnames(classes.root, className)} {...props}>
      <NodeImage
        data={backgroundImage}
        alt={backgroundImageAlt}
        className={classes.backgroundImage}
      />
      <Container component={ResPadding} className={classes.textWrapper}>
        <div className={classes.limitTextWrapperWidth}>
          {title ? (
            <div className={classes.titleWrapper}>
              <Typography
                variant={isSmUp ? 'h2' : 'h4'}
                className={classnames(classes.title, classes.textOverlay)}
              >
                {title}
              </Typography>
            </div>
          ) : null}
          {description ? (
            <div className={classes.descriptionWrapper}>
              <Typography
                variant={isSmUp ? 'h5' : 'body1'}
                className={classnames(classes.description, classes.textOverlay)}
              >
                {description}
              </Typography>
            </div>
          ) : null}
        </div>
      </Container>
      <Button
        variant="contained"
        color="primary"
        disableTouchRipple
        aria-label="Close story"
        size="large"
        onClick={onClose}
        className={classes.action}
      >
        CLOSE
      </Button>
    </div>
  );
};

StoriesItem.defaultProps = {
  className: undefined,
  title: undefined,
  description: undefined,
  backgroundImage: undefined,
  backgroundImageAlt: undefined,
  onClose: undefined,
  textVerticalPosition: undefined,
  textHorizontalPosition: undefined,
  color: undefined,
};

export default withStyles<*, *, Props>(styles)(StoriesItem);
