// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';

import NodeImage from '~plugins/prismic/components/NodeImage';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';

const ImageSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <NodeImage
        data={data?.primary?.slice_image}
        alt={data?.primary?.slice_image?.alt || ''}
        className={classes.image}
      />
    </Component>
  );
};

ImageSlice.defaultProps = {
  className: undefined,
};

export const StyledImageSlice = withStyles<*, *, Props>(styles)(ImageSlice);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledImageSlice>,
>({
  ContainerProps: {
    maxWidth: 'xl',
  },
})(StyledImageSlice);
