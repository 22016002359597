// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';

import NodeBody from '~plugins/prismic/components/NodeBody';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import Heading from '~components/Heading';
import TextParagraphSlice from '~components/slices/TextParagraphSlice';
import CardsGridSlice from '~components/slices/CardsGridSlice';
import ImageSlice from '~components/slices/ImageSlice';
import StoriesSlice from '~components/slices/StoriesSlice';
import HighlightTextSlice from '~components/slices/HighlightTextSlice';
import ARModelSlice from '~components/slices/ARModelSlice';
import type { PrismicHomePage } from '~schema';

import videoFrame from '../images/videoFrame.png';

export type ClassKey = 'root' | 'headingSection' | 'bodySection';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicHomePage>>,
};

const styles = (unusedTheme: Theme) => ({
  root: {
    width: '100%',
    overflow: 'hidden',
  },
  headingSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    height: '100vh',
  },
  bodySection: {},
});

const nodeBodyComponents = {
  text_paragraph: TextParagraphSlice,
  cards_grid: withStyles({
    root: {
      padding: 0,
    },
  })(CardsGridSlice),
  image: withStyles({
    root: {
      padding: 0,
    },
  })(ImageSlice),
  cards_carousel: StoriesSlice,
  highlight: HighlightTextSlice,
  ar_model: ARModelSlice,
};

const HomePage = ({ node, classes }: Props) => {
  return (
    <div className={classes.root}>
      <Heading
        smallBackgroundVideo={node?.data?.small_background_video}
        backgroundVideo={node?.data?.background_video}
        videoFrame={videoFrame}
        siteIcon={node?.data?.image}
        siteIconAlt={node?.data?.image?.alt || ''}
        description={node?.data?.description?.text}
        className={classes.headingSection}
      />
      <NodeBody
        node={node}
        components={nodeBodyComponents}
        className={classes.bodySection}
      />
    </div>
  );
};

export default compose(
  withNodePage<PrismicHomePage, *>({
    getNode: data => data?.prismicHomePage,
  }),
  withStyles<ClassKey, *, Props>(styles),
)(HomePage);

export const query = graphql`
  query HomePageQuery($prismicLocaleId: String) {
    prismicHomePage(lang: { eq: $prismicLocaleId }) {
      id
      lang
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        title {
          text
        }
        description {
          text
          html
        }
        image {
          alt
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 600, quality: 85) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        background_video {
          link_type
          url
        }
        small_background_video {
          link_type
          url
        }
        body {
          ... on PrismicHomePageBodyTextParagraph {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_id {
                text
                html
              }
              slice_background
            }
          }
          ... on PrismicHomePageBodyCardsGrid {
            id
            slice_type
            primary {
              slice_id {
                text
                html
              }
              slice_background
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
            }
          }
          ... on PrismicHomePageBodyImage {
            id
            slice_type
            primary {
              slice_image {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              slice_id {
                text
                html
              }
              slice_background
            }
          }
          ... on PrismicHomePageBodyCardsCarousel {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_button_label {
                text
              }
              slice_id {
                text
                html
              }
              slice_background
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_text_vertical_position
              item_text_horizontal_position
              item_text_color
              item_image {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              item_small_image {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomePageBodyArModel {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_glb_model {
                id
                link_type
                url
              }
              slice_usdz_model {
                id
                link_type
                url
              }
              slice_model_poster {
                localFile {
                  id
                  url
                }
              }
              slice_id {
                text
                html
              }
              slice_background
            }
            items {
              item_slot_id {
                text
                html
              }
              item_data_position {
                text
                html
              }
              item_data_normal {
                text
                html
              }
              item_hotspot_text {
                text
                html
              }
            }
          }
          ... on PrismicHomePageBodyHighlight {
            id
            slice_type
            primary {
              slice_description {
                text
                html
              }
              slice_highlighted_text {
                text
                html
              }
              slice_id {
                text
                html
              }
              slice_background
            }
          }
        }
      }
    }
  }
`;
