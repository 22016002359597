// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  itemsGridContainer: {},
  itemPrimary: {
    color: theme.palette.common.white,
  },
  title: {
    '& b, & strong': {
      color: theme.palette.secondary.main,
      fontWeight: 'initial',
    },
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(4),
    },
    '& p:not(:first-child)': {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '75%',
    },
  },
  itemSecondary: {},
  arViewer: {
    width: '100%',
    height: 600,
    [theme.breakpoints.down('sm')]: {
      height: 500,
    },
    [theme.breakpoints.down('xs')]: {
      height: 400,
    },
  },
});

export default styles;
