// @flow
import * as React from 'react';
import noop from 'lodash/noop';

import BodySection from '../../components/BodySection';

import type { Props, WithBodySectionSliceHOC, Config } from './types';
import defaultBackgroundColor from './helpers/defaultBackgroundColor';
import defaultSliceId from './helpers/defaultSliceId';

export default function withBodySectionSlice<Enhanced: {}>(
  config?: Config<Enhanced>,
): WithBodySectionSliceHOC<Enhanced> {
  const {
    backgroundColor = defaultBackgroundColor,
    backgroundGradientDirection = noop,
    sliceId = defaultSliceId,
    ContainerProps: ContainerPropsDefault,
    ...bodySectionProps
  } = config || {};

  return Component => {
    const WithBodySectionSlice = (props: Props<Enhanced>) => {
      const { ContainerProps } = props;
      return (
        <Component
          {...bodySectionProps}
          id={typeof sliceId === 'function' ? sliceId(props) : sliceId}
          backgroundColor={
            typeof backgroundColor === 'function'
              ? backgroundColor(props)
              : backgroundColor
          }
          backgroundGradientDirection={
            typeof backgroundGradientDirection === 'function'
              ? backgroundGradientDirection(props)
              : backgroundGradientDirection
          }
          {...props}
          ContainerProps={React.useMemo(
            () => ({
              ...ContainerPropsDefault,
              ...ContainerProps,
            }),
            [ContainerPropsDefault, ContainerProps],
          )}
          component={BodySection}
        />
      );
    };

    return WithBodySectionSlice;
  };
}
