// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import Fade from 'react-reveal/Fade';

import RichText from '~plugins/prismic/components/RichText';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';

const ARViewer = React.lazy(() => import('~components/ARViewer'));

const ARModelSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const isSSR = typeof window === 'undefined';
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Grid container spacing={6} className={classes.itemsGridContainer}>
        <Grid item xs={12} md={6} className={classes.itemPrimary}>
          <Fade>
            <div>
              <RichText
                {...data.primary?.slice_title}
                className={classes.title}
              />
              <RichText
                {...data.primary?.slice_description}
                className={classes.description}
              />
            </div>
          </Fade>
        </Grid>
        <Grid item xs={12} md={6} className={classes.itemSecondary}>
          {!isSSR ? (
            <React.Suspense fallback={<div />}>
              <ARViewer
                glbModel={data.primary?.slice_glb_model?.url}
                usdzModel={data.primary?.slice_usdz_model?.url}
                modelPoster={data.primary?.slice_model_poster?.localFile?.url}
                items={data.items}
                className={classes.arViewer}
              />
            </React.Suspense>
          ) : null}
        </Grid>
      </Grid>
    </Component>
  );
};

ARModelSlice.defaultProps = {
  className: undefined,
};

export const StyledARModelSlice = withStyles<*, *, Props>(styles)(ARModelSlice);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledARModelSlice>,
>()(StyledARModelSlice);
