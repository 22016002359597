// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    minHeight: 200,
    '& $highlightedText::before': {
      animation: `$glitch-anim 10s infinite 2s alternate both`,
    },
    '& $highlightedText::after': {
      animation: `$glitch-anim2 10s 1s infinite alternate`,
    },
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexWrap: 'wrap',
  },
  description: ({ data }) => ({
    color:
      data.primary?.slice_background === 'Primary' ||
      data.primary?.slice_background === 'White' ||
      data.primary?.slice_background === 'Grey'
        ? theme.palette.common.black
        : theme.palette.common.white,
  }),
  highlightedText: ({ data }) => ({
    width: 385,
    marginLeft: theme.spacing(1.5),
    position: 'relative',
    color:
      data.primary?.slice_background === 'Secondary'
        ? theme.palette.common.white
        : theme.palette.secondary.main,
    '&::before, &::after': {
      ...theme.typography.h4,
      content: 'attr(data-text)',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    '&::before': {
      left: 4,
      color: theme.palette.secondary.main,
      clipPath: 'inset(20px, 400px, 30px, 0)',
      background: 'white',
    },
    '&::after': {
      left: -1,
      color: theme.palette.secondary.main,
      clipPath: 'inset(40px, 400px, 50px, 0)',
      background: 'white',
    },
  }),
  '@keyframes glitch-anim': {
    // Normal
    '0%': {
      clipPath: 'inset(0 0 0 0)',
    },
    '39%': {
      clipPath: 'inset(0 0 0 0)',
    },
    // Animation
    '40%': {
      clipPath: 'inset(60% 0 81% 0)',
    },
    '41%': {
      clipPath: 'inset(100% 0 70% 0)',
    },
    '42%': {
      clipPath: 'inset(3% 0 10% 0)',
    },
    '43%': {
      clipPath: 'inset(65% 0 38% 0)',
    },
    '44%': {
      clipPath: 'inset(94% 0 77% 0)',
    },
    '45%': {
      clipPath: 'inset(18% 0 43% 0)',
      opacity: 0,
    },
    // Normal
    '46%': {
      clipPath: 'inset(0 0 0 0)',
    },
    '74%': {
      clipPath: 'inset(0 0 0 0)',
    },
    // Animation
    '75%': {
      clipPath: 'inset(60% 0 81% 0)',
    },
    '76%': {
      clipPath: 'inset(100% 0 70% 0)',
    },
    '77%': {
      clipPath: 'inset(3% 0 10% 0)',
    },
    '78%': {
      clipPath: 'inset(65% 0 38% 0)',
    },
    '79%': {
      clipPath: 'inset(94% 0 77% 0)',
    },
    '80%': {
      clipPath: 'inset(18% 0 43% 0)',
      opacity: 0,
    },
    // Normal
    '81%': {
      clipPath: 'inset(0 0 0 0)',
    },
    '94%': {
      clipPath: 'inset(0 0 0 0)',
    },
    // Animation
    '95%': {
      clipPath: 'inset(60% 0 81% 0)',
    },
    '96%': {
      clipPath: 'inset(100% 0 70% 0)',
    },
    '97%': {
      clipPath: 'inset(3% 0 10% 0)',
    },
    '98%': {
      clipPath: 'inset(65% 0 38% 0)',
    },
    '99%': {
      clipPath: 'inset(94% 0 77% 0)',
    },
    '100%': {
      clipPath: 'inset(18% 0 43% 0)',
      opacity: 0,
    },
  },
  '@keyframes glitch-anim2': {
    // Normal
    '0%': {
      clipPath: 'inset(0 0 0 0)',
      textTransform: 'lowercase',
    },
    '39%': {
      clipPath: 'inset(0 0 0 0)',
      textTransform: 'lowercase',
    },
    // Animation
    '40%': {
      clipPath: 'inset(40% 0 61% 0)',
      textTransform: 'uppercase',
    },
    '41%': {
      clipPath: 'inset(92% 0 1% 0)',
      textTransform: 'uppercase',
    },
    '42%': {
      clipPath: 'inset(43% 0 1% 0)',
      textTransform: 'uppercase',
    },
    '43%': {
      clipPath: 'inset(25% 0 58% 0)',
      textTransform: 'uppercase',
    },
    '44%': {
      clipPath: 'inset(54% 0 7% 0)',
      textTransform: 'uppercase',
    },
    '45%': {
      clipPath: 'inset(58% 0 43% 0)',
      textTransform: 'uppercase',
    },
    // Normal
    '46%': {
      clipPath: 'inset(0 0 0 0)',
    },
    '74%': {
      clipPath: 'inset(0 0 0 0)',
    },
    // Animation
    '75%': {
      clipPath: 'inset(40% 0 61% 0)',
      textTransform: 'uppercase',
    },
    '76%': {
      clipPath: 'inset(92% 0 1% 0)',
      textTransform: 'uppercase',
    },
    '77%': {
      clipPath: 'inset(43% 0 1% 0)',
      textTransform: 'uppercase',
    },
    '78%': {
      clipPath: 'inset(25% 0 58% 0)',
      textTransform: 'uppercase',
    },
    '79%': {
      clipPath: 'inset(54% 0 7% 0)',
      textTransform: 'uppercase',
    },
    '80%': {
      clipPath: 'inset(58% 0 43% 0)',
      textTransform: 'uppercase',
    },
    // Normal
    '81%': {
      clipPath: 'inset(0 0 0 0)',
      textTransform: 'lowercase',
    },
    '94%': {
      clipPath: 'inset(0 0 0 0)',
      textTransform: 'lowercase',
    },
    // Animation
    '95%': {
      clipPath: 'inset(40% 0 61% 0)',
      textTransform: 'uppercase',
    },
    '96%': {
      clipPath: 'inset(92% 0 1% 0)',
      textTransform: 'uppercase',
    },
    '97%': {
      clipPath: 'inset(43% 0 1% 0)',
      textTransform: 'uppercase',
    },
    '98%': {
      clipPath: 'inset(25% 0 58% 0)',
      textTransform: 'uppercase',
    },
    '99%': {
      clipPath: 'inset(54% 0 7% 0)',
      textTransform: 'uppercase',
    },
    '100%': {
      clipPath: 'inset(58% 0 43% 0)',
      textTransform: 'uppercase',
    },
  },
});

export default styles;
