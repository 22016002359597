// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import Fade from 'react-reveal/Fade';

import RichText from '~plugins/prismic/components/RichText';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import useSnackbarContext from '~plugins/material-ui/modules/snackbar/hooks/useSnackbarContext';
import ContactForm from '~components/ContactForm';

import type { Props } from './types';
import styles from './styles';

const CardsGridSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const theme = useTheme<Theme>();
  const onlyOnSm = useMediaQuery(theme.breakpoints.only('sm'));
  const snackbar = useSnackbarContext();
  const handleSuccess = ({ resetForm, message }) => {
    resetForm();
    return snackbar.show({ message, variant: 'success' });
  };
  const handleError = ({ message, setErrors }) => {
    setErrors(prevError => ({ ...prevError, email: message }));
  };

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item && item.item_title?.text ? (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              className={classes.itemsGridItem}
            >
              <Fade>
                <div className={classes.itemContent}>
                  <Typography
                    variant="h4"
                    variantMapping={{ h4: 'h2' }}
                    className={classes.title}
                  >
                    {item?.item_title?.text}
                  </Typography>
                  <RichText
                    {...item?.item_description}
                    className={classes.description}
                  />
                </div>
              </Fade>
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Grid container className={classes.itemsGridContainer}>
        {renderedItems}
        {onlyOnSm ? (
          <Grid
            item
            sm={6}
            className={classnames(
              classes.itemsGridItem,
              classes.itemGridHighlighted,
            )}
          >
            <Fade>
              <div className={classes.itemContent}>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.description}
                >
                  Want to know more about the project?
                </Typography>
                <ContactForm
                  onSuccess={handleSuccess}
                  onError={handleError}
                  classes={{ root: classes.form }}
                />
              </div>
            </Fade>
          </Grid>
        ) : null}
      </Grid>
    </Component>
  );
};

CardsGridSlice.defaultProps = {
  className: undefined,
};

export const StyledCardsGridSlice = withStyles<*, *, Props>(styles)(
  CardsGridSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledCardsGridSlice>,
>({
  ContainerProps: {
    maxWidth: 'xl',
  },
})(StyledCardsGridSlice);
