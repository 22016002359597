// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  textWrapper: {
    textAlign: 'center',
  },
  title: {
    color: ({ data }) =>
      data.primary?.slice_background === 'Secondary'
        ? theme.palette.common.white
        : theme.palette.secondary.main,
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(4),
    },
    '& p:not(:first-child)': {
      paddingTop: theme.spacing(2),
    },
    color: ({ data }) =>
      data.primary?.slice_background === 'Primary' ||
      data.primary?.slice_background === 'White' ||
      data.primary?.slice_background === 'Grey'
        ? theme.palette.common.black
        : theme.palette.common.white,
  },
  action: {
    marginTop: theme.spacing(4),
    minWidth: 225,
  },
  storiesContainer: {
    '-webkit-user-select': 'none',
    '-webkit-touch-callout': 'none',
  },
  fallbackWrapper: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignItem: 'center',
  },
  fallback: {},
  story: {},
  storyTextWrapper: {},
  storyTitle: {},
  storyDescription: {},
  storyImage: {},
  storyCloseIcon: {},
});

export default styles;
