// @flow
import * as React from 'react';

import type { Props } from './types';

const getDefaultBody = node =>
  (node && node.data && node.data.body) || undefined;

/**
 * Render body slices for the given node, using the provided components in
 * the `components` dictionary prop, retrieved by each slice's `slice_type`.
 */
const NodeBody = ({ node, body, components, ...props }: Props) => {
  const slices = body || getDefaultBody(node);
  return node && slices ? (
    <>
      {slices.map((slice, index) => {
        const { id, slice_type: sliceType } = slice || {};
        if (slice && sliceType && components[sliceType]) {
          const Component = components[sliceType];
          return (
            <Component
              {...props}
              key={id || index}
              data={slice}
              node={node}
              index={index}
            />
          );
        }
        return null;
      })}
    </>
  ) : null;
};

export default NodeBody;
