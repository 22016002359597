// @flow
import type { Styles } from './types';
import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {},
  itemsGridContainer: {
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          marginRight: theme.spacing(-getBreakPointFactor(breakpoint)),
          marginLeft: theme.spacing(-getBreakPointFactor(breakpoint)),
          width: `calc(100% + ${theme.spacing(getBreakPointFactor(breakpoint)) *
            2}px )`,
        },
      }),
      {},
    ),
  },
  itemsGridItem: {
    border: '0.5px solid #474747',
    position: 'relative',
    width: '100%',
    paddingBottom: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '50%',
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: '33.333333%',
    },
  },
  itemGridHighlighted: {
    backgroundColor: theme.palette.secondary.dark,
  },
  itemContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(3),
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  title: {
    color: ({ data }) =>
      data.primary?.slice_background === 'Secondary'
      ? theme.palette.common.white
      : theme.palette.secondary.main
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(4),
    },
    '& a': {
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      fontFamily: 'Work Sans',
      textTransform: 'uppercase',
      textDecoration: 'none',
    },
    color: ({ data }) =>
      data.primary?.slice_background === 'Primary' ||
      data.primary?.slice_background === 'White' ||
      data.primary?.slice_background === 'Grey'
        ? theme.palette.common.black
        : theme.palette.common.white,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.common.white,
    paddingTop: theme.spacing(3),
  },
});

export default styles;
