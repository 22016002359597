// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';

export type ClassKey = 'root' | 'overlay' | 'video';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  videoSrcURL: ?string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    zIndex: 0,
    pointerEvents: 'none',
  },
  overlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    backgroundColor: theme.palette.secondary.main,
    filter: 'opacity(0.2) brightness(0.2)',
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    alignItems: 'stretch',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
});

const BackgroundVideo = ({
  videoSrcURL,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      <div className={classes.overlay} />
      <video
        src={videoSrcURL}
        autoPlay
        loop
        muted
        playsInline
        className={classes.video}
      >
        <track kind="captions" />
      </video>
    </div>
  );
};

BackgroundVideo.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(BackgroundVideo);
