// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';

const HighlightTextSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <div className={classes.wrapper}>
        <RichText
          {...data.primary?.slice_description}
          className={classes.description}
        />
        <RichText
          {...data.primary?.slice_highlighted_text}
          data-text={data.primary?.slice_highlighted_text?.text}
          className={classes.highlightedText}
        />
      </div>
    </Component>
  );
};

HighlightTextSlice.defaultProps = {
  className: undefined,
};

export const StyledHighlightTextSlice = withStyles<*, *, Props>(styles)(
  HighlightTextSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledHighlightTextSlice>,
>()(StyledHighlightTextSlice);
