// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  wrapper: {
    textAlign: 'center',
  },
  title: {
    color: ({ data }) =>
      data.primary?.slice_background === 'Secondary'
        ? theme.palette.common.white
        : theme.palette.secondary.main,
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(4),
    },
    color: ({ data }) =>
      data.primary?.slice_background === 'Primary' ||
      data.primary?.slice_background === 'White' ||
      data.primary?.slice_background === 'Grey'
        ? theme.palette.common.black
        : theme.palette.common.white,
  },
});

export default styles;
