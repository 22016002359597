// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import Fade from 'react-reveal/Fade';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import NodeImage from '~plugins/prismic/components/NodeImage';
import useSnackbarContext from '~plugins/material-ui/modules/snackbar/hooks/useSnackbarContext';
import BackgroundVideo from '~components/BackgroundVideo';
import ContactForm from '~components/ContactForm';

import type { Props } from './types';
import styles from './styles';

const Heading = ({
  smallBackgroundVideo,
  backgroundVideo,
  videoFrame,
  siteIcon,
  siteIconAlt,
  description,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const theme = useTheme<Theme>();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const snackbar = useSnackbarContext();
  const handleSuccess = ({ resetForm, message }) => {
    resetForm();
    return snackbar.show({ message, variant: 'success' });
  };
  const handleError = ({ message, setErrors }) => {
    setErrors(prevError => ({ ...prevError, email: message }));
  };

  return (
    <div className={classnames(classes.root, className)} {...props}>
      {backgroundVideo?.url || smallBackgroundVideo?.url ? (
        <BackgroundVideo
          videoSrcURL={
            isSmDown ? smallBackgroundVideo?.url : backgroundVideo?.url
          }
          className={classes.backgroundVideo}
        />
      ) : (
        <img src={videoFrame} alt="Quantic video frame" />
      )}
      <Container component={ResPadding} vertical className={classes.wrapper}>
        <Fade>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <NodeImage
                data={siteIcon}
                alt={siteIconAlt}
                className={classes.siteIcon}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {description ? (
                <Typography variant="h6" className={classes.description}>
                  {description}
                </Typography>
              ) : null}
              <ContactForm
                name="contact form"
                onSuccess={handleSuccess}
                onError={handleError}
                classes={React.useMemo(
                  () => ({
                    root: classes.form,
                    formField: classes.formField,
                    formButton: classes.formButton,
                    formPrivacyPolicy: classes.formPrivacyPolicy,
                  }),
                  [classes],
                )}
              />
            </Grid>
          </Grid>
        </Fade>
      </Container>
    </div>
  );
};

Heading.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(Heading);
