// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import RichText from '~plugins/prismic/components/RichText';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';

import type { Props } from './types';
import styles from './styles';

const TextParagraphSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Container
        component={ResPadding}
        maxWidth="sm"
        className={classes.wrapper}
      >
        <Typography variant="h4" className={classes.title}>
          {data.primary?.slice_title?.text}
        </Typography>
        <RichText
          {...data.primary?.slice_description}
          className={classes.description}
        />
      </Container>
    </Component>
  );
};

TextParagraphSlice.defaultProps = {
  className: undefined,
};

export const StyledTextParagraphSlice = withStyles<*, *, Props>(styles)(
  TextParagraphSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledTextParagraphSlice>,
>()(StyledTextParagraphSlice);
