// @flow
import * as React from 'react';
import Image from 'gatsby-image/withIEPolyfill';
import { oneLine } from 'common-tags';

import FileImage from './FileImage';
import type { File } from '~schema';

export type ImageData = {
  dimensions?: ?{ [any]: any, width: number, height: number },
  alt?: ?string,
  copyright?: ?string,
  url?: ?string,
  localFile?: ?File,
};

export type Props = {
  ...$Exact<
    $Diff<
      React.ElementConfig<typeof FileImage>,
      {
        file: any,
      },
    >,
  >,
  data: ?ImageData,
  fluid?: {
    aspectRatio?: number,
    src?: string,
    srcSet?: string,
    sizes?: string,
  },
  fixed?: {
    width?: number,
    height?: number,
    src?: string,
    srcSet?: string,
  },
};

export const hasNodeImageValue = (data: $ElementType<Props, 'data'>): boolean =>
  !!(data && data.url && data.dimensions) ||
  !!(data && data.localFile && data.localFile.childImageSharp);

const NodeImage = ({
  ImageComponent = Image,
  data,
  fluid,
  fixed,
  ...props
}: Props) =>
  data && data.url && data.dimensions ? (
    <ImageComponent
      alt={data.alt || ''}
      {...props}
      fluid={
        fluid
          ? {
              src: data.url,
              srcSet: data.url,
              aspectRatio: data.dimensions.width / data.dimensions.height,
              sizes: oneLine`
                (max-width: ${data.dimensions.width}) 100vw,
                ${data.dimensions.width}
              `,
              ...fluid,
            }
          : undefined
      }
      fixed={
        fixed
          ? {
              src: data.url,
              srcSet: data.url,
              ...data.dimensions,
              ...fixed,
            }
          : undefined
      }
    />
  ) : (
    <FileImage
      alt={data?.alt || ''}
      {...props}
      file={data?.localFile}
      ImageComponent={ImageComponent}
    />
  );

NodeImage.defaultProps = {
  fluid: {},
  fixed: undefined,
};

// eslint-disable-next-line react/display-name
export default React.forwardRef<React.ElementConfig<typeof NodeImage>, *>(
  (props, ref) => <NodeImage innerRef={ref} {...props} />,
);
